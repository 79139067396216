<supy-filter-group
  class="supy-retailers-selection__filters"
  [filterGroup]="filtersGroup"
  [latency]="500"
  (filtersChange)="onApplyFilters($event)"
  (filtersReset)="onClearFilters()"
/>
<supy-grid-poc
  class="supy-retailers-selection__grid"
  height="100%"
  domLayout="normal"
  [columnDefs]="columnDefs"
  [data]="retailers()"
  [disableSelectOnClick]="true"
  rowSelection="multiple"
  (cellClick)="onCellClick($event)"
  [isPaginated]="true"
  [pageIndex]="requestMetadata().page"
  [perPage]="requestMetadata().limit"
  [prevPageDisabled]="requestMetadata().page === 0"
  [nextPageDisabled]="responseMetadata().count < requestMetadata().limit"
  (pageChange)="onPageChange($event)"
/>

<supy-loading-overlay *ngIf="isPreparingRetailer() || (isLoading$ | async)" />
