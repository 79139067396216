import { produce } from 'immer';
import { first, map, switchMap, tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BASE_REQUEST_META_DEFAULT,
  BASE_RESPONSE_META_DEFAULT,
  BaseRequestMetadata,
  BaseResponseMetadata,
  BranchType,
  EntityListState,
  EntityListStateModel,
  OutletStateEnum,
  Query,
  QueryBuilder,
  QueryPaging,
  Retailer,
  RetailerState,
  UserWithBranches,
} from '@supy/common';
import { CurrentRetailerSet, OutletsProps, OutletsService, RetailersService } from '@supy/retailers';
import { CurrentUserState, GetCurrentUserSuccess } from '@supy/users';

import { ImpersonationRetailersFilters, ImpersonationRetailersRequestProps } from '../core';
import {
  ImpersonationRetailersGetCurrentUser,
  ImpersonationRetailersGetMany,
  ImpersonationRetailersInitFilters,
  ImpersonationRetailersPatchFilters,
  ImpersonationRetailersPatchRequestMetadata,
  ImpersonationRetailersResetFilters,
  ImpersonationRetailersSelectRetailer,
} from './impersonation-retailers.actions';

export interface ImpersonationRetailersStateModel extends EntityListStateModel<Retailer> {
  readonly filters: ImpersonationRetailersFilters;
  readonly requestMetadata: BaseRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
  readonly selectedRetailer: Retailer | null;
}

const FILTERS_DEFAULT: ImpersonationRetailersFilters = {
  name: null,
  country: null,
  city: null,
};

export const IMPERSONATION_RETAILERS_STATE_TOKEN = new StateToken<ImpersonationRetailersStateModel>(
  'impersonationRetailers',
);

@State<ImpersonationRetailersStateModel>({
  name: IMPERSONATION_RETAILERS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    requestMetadata: BASE_REQUEST_META_DEFAULT,
    responseMetadata: BASE_RESPONSE_META_DEFAULT,
    selectedRetailer: null,
  },
})
@Injectable()
export class ImpersonationRetailersState extends EntityListState implements NgxsOnInit {
  private readonly store = inject(Store);
  private readonly retailersService = inject(RetailersService);
  private readonly outletsService = inject(OutletsService);

  ngxsOnInit(ctx: StateContext<ImpersonationRetailersStateModel>) {
    ctx.dispatch(new ImpersonationRetailersGetCurrentUser());
  }

  @Selector()
  static filters(state: ImpersonationRetailersStateModel) {
    return EntityListState.currentFilters<ImpersonationRetailersFilters>(state);
  }

  @Selector()
  static override appliedFiltersCount(state: ImpersonationRetailersStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static selectedRetailer(state: ImpersonationRetailersStateModel) {
    return state?.selectedRetailer;
  }

  @Selector()
  static requestMetadata(state: ImpersonationRetailersStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: ImpersonationRetailersStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static retailers(state: ImpersonationRetailersStateModel) {
    return EntityListState.all(state);
  }

  @Action(ImpersonationRetailersGetMany, { cancelUncompleted: true })
  getRetailers(ctx: StateContext<ImpersonationRetailersStateModel>) {
    return this.retailersService.getMany(this.getQuery(ctx.getState()) as unknown as Query<Retailer>).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(ImpersonationRetailersSelectRetailer)
  selectRetailer(
    ctx: StateContext<ImpersonationRetailersStateModel>,
    { payload }: ImpersonationRetailersSelectRetailer,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedRetailer = payload ?? null;
      }),
    );
  }

  @Action(ImpersonationRetailersInitFilters)
  retailersInitFilter(ctx: StateContext<ImpersonationRetailersStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT, { saveToUrl: false });
  }

  @Action(ImpersonationRetailersPatchFilters)
  retailersPatchFilter(
    ctx: StateContext<ImpersonationRetailersStateModel>,
    { payload }: ImpersonationRetailersPatchFilters,
  ) {
    super.patchFilter(ctx, payload, FILTERS_DEFAULT, { saveToUrl: false });
    ctx.dispatch([new ImpersonationRetailersPatchRequestMetadata({ page: 0 }), new ImpersonationRetailersGetMany()]);
  }

  @Action(ImpersonationRetailersResetFilters)
  resetRetailersFilter(ctx: StateContext<ImpersonationRetailersStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
    ctx.dispatch([new ImpersonationRetailersPatchRequestMetadata({ page: 0 }), new ImpersonationRetailersGetMany()]);
  }

  @Action(ImpersonationRetailersPatchRequestMetadata)
  patchRequestMetadata(
    ctx: StateContext<ImpersonationRetailersStateModel>,
    { payload }: ImpersonationRetailersPatchRequestMetadata,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(ImpersonationRetailersGetCurrentUser)
  getCurrentUser(ctx: StateContext<ImpersonationRetailersStateModel>) {
    const retailer = ctx.getState().selectedRetailer;

    if (retailer) {
      // update current user state with selected retailer instead of user retailers (if any)
      const retailerId = retailer.id;
      const outletsQuery = new Query<OutletsProps>({
        paging: QueryPaging.NoLimit,
        filtering: [
          { by: 'retailer.id', op: 'eq', match: retailerId },
          { by: 'state', op: 'eq', match: OutletStateEnum.Active },
        ],
      });

      this.store.dispatch(new CurrentRetailerSet({ id: retailerId, stopRedirect: true }));

      this.store
        .select(CurrentUserState.getCurrentUser)
        .pipe(
          first(Boolean),
          switchMap(() => this.outletsService.getOutletsBff(outletsQuery).pipe(map(res => res.data))),
        )
        .subscribe(outlets => {
          const branches = outlets.flatMap(outlet =>
            outlet.branches.map(branch => ({
              ...branch,
              retailer,
              retailerId: retailer.id,
            })),
          );

          const centralKitchens = branches.filter(branch => branch.type === BranchType.centralKitchen);
          const currentUser = this.store.selectSnapshot(CurrentUserState.getCurrentUser) as UserWithBranches;

          const actionsToDispatch: (GetCurrentUserSuccess | CurrentRetailerSet | Navigate)[] = [
            new GetCurrentUserSuccess({
              ...currentUser,
              retailers: [{ ...retailer, outlets, centralKitchens }],
              retailerIds: [retailerId],
              outletIds: outlets.map(({ id }) => id),
              branchIds: branches.map(({ id }) => id),
              centralKitchenIds: centralKitchens.map(({ id }) => id),
            }),
          ];

          if (window.location.href === 'retailers-list') {
            // Redirect to orders if navigate to list by direct link with selected retailer
            actionsToDispatch.push(new Navigate(['orders']));
          }
          this.store.dispatch(actionsToDispatch);
        });
    }
  }

  private getQuery(state: ImpersonationRetailersStateModel): Query<Retailer & ImpersonationRetailersRequestProps> {
    const { name, country, city } = state.filters;

    const qb = new QueryBuilder<Retailer & ImpersonationRetailersRequestProps>({
      filtering: [{ by: 'state', op: 'neq', match: RetailerState.deleted }],
      paging: {
        offset: state.requestMetadata.page * state.requestMetadata.limit,
        limit: state.requestMetadata.limit,
      },
      ordering: [{ by: 'name', dir: 'asc' }],
    });

    if (name) {
      qb.filtering.setFilter({
        by: 'name',
        match: name,
        op: 'like',
      });
    }

    if (country) {
      qb.filtering.setFilter({
        by: 'country.id',
        match: country,
        op: 'eq',
      });
    }

    if (city) {
      qb.filtering.setFilter({
        by: 'country.cities',
        match: [city],
        op: 'in',
      });
    }

    return qb.build();
  }
}
